<template>
  <div>
    <!-- 公共头部 -->
    <Header></Header>

    <div class="view" ref="pageview">
      <img class="personal_bg" src="@/assets/images/personal_bg.jpg" />
      <div class="section1">
        <img class="ava" src="@/assets/images/icon34.png" />
        <div class="userinfo" v-show="userInfo">
          <div class="name-box">
            <div class="name">
              {{ userInfo.name ? userInfo.name : "匿名用户" }}
            </div>
            <img
              class="icon"
              v-if="userInfo.sex == '男'"
              src="@/assets/images/icon50.png"
            />
          </div>
          <div class="tel">{{ userInfo.tel }}</div>
        </div>
        <div class="button-group" v-show="!userInfo">
          <router-link
            :to="{
              path: '/login',
              name: 'login',
              params: { url: $route.path },
            }"
            class="btn"
            >登录</router-link
          >
          <router-link
            :to="{
              path: '/login',
              name: 'login',
              params: { url: $route.path, curIndex: 2 },
            }"
            class="btn1"
            >注册</router-link
          >
        </div>
        
      </div>
     
      <div class="section3">
        <div class="item" @click="navTo('/userInfo/index')">
          <div class="pic">
            <img class="icon" src="@/assets/images/icon41.png" />
          </div>
          <div class="title">个人信息</div>
        </div>
        <div class="item" @click="navToWx">
          <div class="pic">
            <img class="icon" src="@/assets/images/icon42.png" />
          </div>
          <div class="title">学习中心</div>
        </div>

        <div class="item" @click="navTo('/invoice/index')">
          <div class="pic">
            <img class="icon" src="@/assets/images/icon43.png" />
          </div>
          <div class="title">开票资料</div>
        </div>
        <div class="item" @click="navTo('/message/list')">
          <div class="pic">
            <img class="icon" src="@/assets/images/icon44.png" />
          </div>
          <div class="title">消息通知</div>
        </div>
        <div class="item" @click="navTo('/order/list')">
          <div class="pic">
            <img class="icon" src="@/assets/images/icon45.png" />
          </div>
          <div class="title">我的订单</div>
        </div>
        <div class="item" @click="navTo('/contact')">
          <div class="pic">
            <img class="icon" src="@/assets/images/icon46.png" />
          </div>
          <div class="title">客服中心</div>
        </div>
       
        <div class="item" @click="navTo('/help?id=3')">
          <div class="pic">
            <img class="icon" src="@/assets/images/icon47.png" />
          </div>
          <div class="title">帮助中心</div>
        </div>
      </div>
      <div v-show="userInfo" class="exit_btn" @click="logout">用户退出</div>
    </div>
    <div class="emask" v-if="showEm" @click="showEm = false">
      <div class="ewm">
        <div class="img"><img src="@/assets/images/erweima.jpg" alt="" /></div>
        <div class="title">请扫描二维码进入小程序学习</div>
      </div>
    </div>

    <!-- 公共底部 -->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "@/components/Public/Header.vue";
import Footer from "@/components/Public/Footer.vue";


export default {
  components: {
    Header,
    Footer,
    open: {
      template: "#open",
    },
  },
  data() {
    return {
      userInfo: "",
      bgcol: "#fff",
      showEm: false,
      wh: "",
      ww: "",
      env: "",
      wxHtml: "",
      id: "",
  
    };
  },
  computed: {
    uid() {
       return this.$store.state.uid;
    },
  },
  activated() {
    this.getUserInfo();
    
  },
  created() {
    this.getConfig();
    
  },
  mounted() {
    let wxJs = document.createElement("script");
    wxJs.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js";
    document.head.appendChild(wxJs);
    var ww = document.documentElement.clientWidth || window.innerWidth;
    var wh = document.documentElement.clientHeight || window.innerHeight;
    this.ww = ww;
    this.wh = wh;
   

  },
  methods: {

    async getConfig() {
      let url = location.href.split("#")[0];
    },
    navToWx(e) {
      let that = this;
      var ua = navigator.userAgent.toLowerCase();
      let userid = this.userInfo.id;
      if (!userid) {
        return this.$messageBox.alert("请先登录", "温馨提示");
      }
    var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
         } else {
            this.showEm = true;
          }
        });
      } else {
        this.showEm = true;
      }
   if (!this.showEm) {
      this.$router.push("/learnCenter/Index");
   }
    },
    async getUserInfo() {
       let user = window.localStorage.getItem('userInfo');
      if (user&&user.id) {
         this.userInfo = user;
        this.$store.commit("saveUserInfo", user);
        console.log( this.$store.state.userInfo.id);
       }else{
 if (this.uid) {
        var data = {
          uid: this.uid,
        };
   
        this.$toast.loading("加载中...");
        const { data: res } = await this.$http.get("/user/userInfo", {
          params: data,
        });
        this.$toast.close();
        if (this.$store.state.beta) {
          console.log(
            "个人信息：" +
              new Date().getHours() +
              ":" +
              new Date().getMinutes() +
              ":" +
              new Date().getSeconds(),
            res
          );
        }
        var code = res.code;
        if (code == 1) {
          var userInfo = res.data;
          this.$store.commit("saveUserInfo", userInfo);
          this.userInfo = userInfo;
        } else {
          this.$messageBox.alert(res.data, "温馨提示");
          this.userInfo = "";
        }
      }
       }
      
     
    },
    handleLaunchFn(e) {
      console.log("success", e);
    },
    handleErrorFn(e) {
      console.log("fail", e.detail);
    },
    async navTo(url) {
      console.log(url=='/help?id=3');
      if (url!=='/help?id=3'&&url!=='/contact') {
        if (!this.uid) {
        const confirmResult = await this.$messageBox
          .confirm("您当前未登录，请登录后再进行操作！", "登录提醒")
          .catch((error) => error);
        if (confirmResult != "confirm") {
          return this.$toast("已取消操作");
        }
        wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
					         wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
      } else {
      this.$router.push({path:url,query:{title:"帮助中心"}})
     }
      }else {
         this.$router.push({path:url,query:{title:"帮助中心"}})
      }
      
    },
     logout() {
        let opid =window.localStorage.getItem('openid')
      this.$messageBox
        .confirm("是否退出", "提示")
        .then(async () => {
          window.localStorage.clear("userInfo");
          const { data: res } = await this.$http.get("/login/signout", {
          params: {openid:opid},
        });
        console.log(res);
          this.$store.commit("saveUserInfo", "");
          this.userInfo = "";
          this.$messageBox.alert("退出成功");
          this.$router.push("/index");
        })
        .catch(() => {
          this.$messageBox.alert("已取消退出");
        });
    },
  },
};
</script>

<style scoped>
.view {
  position: relative;
  padding: 1.35rem 0.25rem 0.75rem;
}
.view .personal_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}
.section1 {
  background: #ffffff;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  border-radius: 0.14rem;
  position: relative;
  padding-top: 1.15rem;
}
.section1 .ava {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.section1 .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.6rem;
}
.section1 .button-group .btn {
  font-size: 0.26rem;
  color: #ffffff;
  width: 1rem;
  height: 0.5rem;
  background: #0472c7;
  border-radius: 0.05rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.15rem;
}
.section1 .button-group .btn1 {
  font-size: 0.26rem;
  color: #ffffff;
  width: 1rem;
  height: 0.5rem;
  background: #86cb08;
  border-radius: 0.05rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.15rem;
}
.section1 .userinfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.4rem;
}
.section1 .userinfo .name-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.section1 .userinfo .name-box .name {
  font-size: 0.4rem;
  color: #333333;
}
.section1 .userinfo .name-box .icon {
  width: 0.24rem;
  height: 0.24rem;
  margin-left: 0.18rem;
}
.section1 .userinfo .tel {
  font-size: 0.3rem;
  color: #757575;
  margin-top: 0.16rem;
}
.section1 .list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 0.02rem solid #e9e9e9;
}
.section1 .list .item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0;
  position: relative;
}
.section1 .list .item::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0.02rem;
  height: 0.6rem;
  background: #e9e9e9;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
}
.section1 .list .item:last-child::after {
  display: none;
}
.section1 .list .item .icon {
  width: 0.8rem;
}
.section1 .list .item .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.16rem;
}
.section1 .list .item .title {
  font-size: 0.32rem;
  color: #333333;
  margin-bottom: 0.06rem;
}
.section1 .list .item .desc {
  font-size: 0.24rem;
  color: #757575;
}

.section2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  border-radius: 0.14rem;
  margin-top: 0.4rem;
  padding: 0.3rem 0;
}
.section2 .item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section2 .item .pic {
  width: 0.5rem;
  height: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section2 .item .pic .icon {
  max-height: 100%;
}
.section2 .item .title {
  font-size: 0.28rem;
  color: #333333;
  margin-top: 0.12rem;
}

.section3 {
  background: #ffffff;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  border-radius: 0.14rem 0.14rem 0 0;
  margin-top: 0.4rem;
}
.section3 .item {
  display: flex;
  align-items: center;
  height: 1rem;
  border-bottom: 0.02rem solid #e7e7e7;
  padding: 0 0.3rem;
  background: url(../../assets/images/icon49.png) no-repeat right 0.34rem center;
  background-size: 0.09rem 0.17rem;
}
.section3 .item:last-child {
  /* border-bottom: none; */
}
.section3 .item .pic {
  width: 0.36rem;
  height: 0.36rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section3 .item .pic .icon {
  max-height: 100%;
}
.section3 .item .title {
  font-size: 0.28rem;
  color: #666666;
  margin-left: 0.2rem;
}
#launch-btn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: center;
}
.exit_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0px 0px 10px 10px;
  background-color: #86cb08;
  height: 1rem;
  color: #ffffff;
  border-bottom: 0.02rem solid #e7e7e7;
  padding: 0 0.3rem;
  box-shadow: 0 0 0.1em rgb(0 0 0 / 20%);
  /* background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAARCAYAAAAPFIbmAAAAeElEQVQokZ3SOwrCQBAA0IdgIdFGCDa5gSfMXUQR7xBLEeysrQyIJ1GEaXeyOPVj/vDGCQtJjPjghq7ENjgHfKEtwRl2AYes7Bx9wCOaDO8DXrAuoSWuAR/YluAKh4D3rOwvngH/y1TVUzrd5J4mN151u6ovyP8JX010KEU5zzHgAAAAAElFTkSuQmCC) no-repeat right 0.34rem center;
    background-size: 0.09rem 0.17rem; */
}

.emask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 0.28rem;
  text-align: center;
}
.emask .ewm {
  width: 90%;
  height: 6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
}
.emask .ewm .img {
  width: 4rem;
  display: block;
  margin: 0 auto;
}
.hidep {
  overflow: hidden;
}
</style>